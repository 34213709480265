import { IConfig } from './Request';
import { isFunction } from 'ks-utilities/lib/isFunction';

export class InterceptorPointModel<T, K> {
  _interceptors: Array<[T, (config: IConfig) => boolean, boolean]> = [];

  use(interceptor: T, check?: (config: IConfig, _?: any, __?: any) => boolean, isMutation?: boolean) {
    this._interceptors.push([interceptor, check, isMutation]);
  }
  eject(interceptor: T) {
   this._interceptors = this._interceptors.filter((item) => item[0] !== interceptor);
  }
  _apply(element: K, config: IConfig, xhr: any): K {
    let result = element;
    this._interceptors.forEach(async (item) => {
      if (item[1] && !(item[1] as any)(config)) { // if we send check function
        return;
      }
      if (isFunction(item[0])) { // run interceptor
        result = await (item[0] as any)(result, xhr);
        if (result !== element  && !item[2]) {
          throw new Error('InterceptorPointModel(apply): element should be mutation. Don\'t return new object.');
        }
      }

    });
    return result;
  }
}
