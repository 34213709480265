export function mutateCardsShopLink(card: IItemCard) {
  if (!card.hasVariants || !card.configSku) {
    return;
  }
  const regex = new RegExp(/\d+b?(?=[\/?])/);
  const sku = regex.exec(card.shopLink)?.[0];

  if (sku) {
    card.shopLink = card.shopLink.replace(sku, card.configSku);
  }
}
