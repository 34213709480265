export function getProductBonus(product: any): string | null {
    const bonusType = 'BONUS';

    if (!product.promo) {
        return null;
    }

    const bonus = product.promo.filter((promoItem: { type: string }) => promoItem.type === bonusType);

    if (bonus.length) {
        let sum = 0;

        bonus.forEach((promoItem: IBadge) => {
            if (promoItem.priority) {
                sum+=promoItem.priority;
            }
        });

        if (sum === 0) {
            return null;
        }

        return `${sum}%`;
    }

    return null;
}
