export class GatewayEndpoint {
  // categoryGateway = '/yml/main-nav/mainnav';

  /**
   * content item
   */
  productData = '/yml/content/item/product/:productCode';
  configuratorData = '/yml/content/item/product/configurator';
  sizeTableData = '/yml/content/item/api/v1/size-table';
  productLimits = '/yml/content/item/product/limits';

  /**
   * product-view
   */
  productGateway = '/yml/product-view/product';
  ordersProduct = '/yml/product-view/orders/product';
  productResults = '/yml/product-view/pl/results'; // product list
  productFilters = '/yml/product-view/pl/filters';
  productFilter = '/yml/product-view/pl/filter';
  productAvailability = '/yml/product-view/availability';
  searchAutocomplete = '/yml/product-view/search/autocomplete';
  productMinBenefitValue = '/yml/product-view/pl/minimum-benefit-range-value';

  /**
   * favorite-products
   */
  favoriteProducts =
    '/yml/favorite-products/shop/rest/customer/favoriteProducts';
  favoriteProductsAll =
    '/yml/favorite-products/shop/rest/customer/allFavoriteProducts';
  favoriteProductsCheck =
    '/yml/favorite-products/shop/rest/customer/checkFavoriteProducts';
  favoriteProductsCount =
    '/yml/favorite-products/shop/rest/customer/deviceFavoriteProductsCount';
  favoriteProductsExist =
    '/yml/favorite-products/shop/rest/customer/deviceFavoriteProductsExist';

  /**
   * offer-view
   */
  offersByFilterGateway = '/yml/offer-view/offers';
  offersByMasterGateway = '/yml/offer-view/offers/:id';

  /**
   * cart
   */
  cartEntry = '/yml/cart/shop/rest/misc/sf-cart/entry';
  cartEntryWithRecount = '/yml/cart/shop/rest/misc/cart/entry';
  cartEntries = '/yml/cart/shop/rest/misc/cart/entries';
  cartData = '/yml/cart/shop/cart/full-data';
  cartInfo = '/yml/cart/shop/rest/misc/cart';
  addToCart = '/yml/cart/shop/cart/actions/add';
  cleanCheckoutCart = '/yml/cart/shop/checkout/end';
  // startCheckoutCart = '/yml/cart/shop/checkout/start';
  cartShare = '/yml/cart/shop/cart/share';
  receiveSharedCart = '/yml/cart/shop/cart';
  addToCartSharedEntries = '/yml/cart/actions/add/multi-item';
  productMaxQuantity = '/yml/cart/shop/max-quantity';
  cartInfoPriorityMerchant = '/yml/cart/shop/priority-merchant';
  cartCheckoutStart = '/yml/cart/shop/checkout/start_checked';
  invoiceData = '/yml/cart/shop/checkout/api/invoice/view/:invoice';
  invoicePaymentPrepare =
    '/yml/cart/shop/checkout/api/invoice/prepare/:invoiceID';
  invoicePayment = '/yml/cart/shop/checkout/api/invoice/pay/:invoiceID';
  recalculateCartGroup = '/yml/cart/shop/checkout/api/delivery/recalculate';

  /**
   * Checkout
   */
  checkoutDecision = '/yml/payment/checkout/decision';
  checkoutCart = '/yml/payment/checkout/cart';
  checkPhoneNumbersInBlacklist = '/yml/blacklist/phone/contains';
  loanForm = '/yml/payment/checkout/loanapplicationform';
  checkoutPrepare = '/yml/cart/shop/checkout/api/prepare';
  checkoutLoanPayment = '/yml/cart/shop/checkout/api/loan-payment';
  checkoutPlacement = '/yml/cart/shop/checkout/api/placement';

  /**
   * certificates
   */

  createCertificate = '/yml/cart/shop/checkout/api/add-and-prepare';
  checkoutDigitalPlacement = '/yml/cart/shop/checkout/api/e-placement';

  /**
   * address-book
   */
  getAddress = '/yml/address-book/address/find';
  createAddress = '/yml/address-book/address';
  changeAddress = '/yml/address-book/address/:addressId';
  appliedAddressId = '/yml/address-book/address/:id/touch';
  appliedPostomatId = '/yml/address-book/pos/:id/touch';
  getAddressZoneInfo = '/yml/address-book/address/zone-info';
  confirmOrderAddress = '/yml/address-book/address/confirmation';
  getNearestAddress = '/yml/address-book/api/v1/address/nearest';

  /**
   * postomat-book
   */
  getPostomatBook = '/yml/address-book/pos/find';
  createPostomatBook = '/yml/address-book/pos';
  changePostomatBook = '/yml/address-book/pos/:posId';

  /**
   * reviews
   */
  getReviewById = '/yml/creview/review/productreview/:reviewId';
  productReviews = '/yml/review-view/api/v1/reviews/product/:productCode';
  // for configurator
  productReviewsBucket = '/yml/review-view/api/v1/reviews/bucket/:baseProductCode';
  reviewSummary = '/yml/creview/rest/misc/product/:productCode/summary';
  reviewGroupSummary =
    '/yml/creview/rest/misc/product/:productCode/groupsummary';
  merchantReviews =
    '/yml/review-view/api/v1/reviews/merchant/:merchantCode';
  productReviewsFeedback = '/yml/creview/rest/misc/review/feedback';
  reviewTags = '/yml/creview/rest/misc/tags';
  merchantSummary = '/yml/creview/rest/misc/merchant/:merchantCode/summary';
  merchantGroupSummary =
    '/yml/creview/rest/misc/merchant/:merchantCode/groupsummary';
  reviewTeasers = '/yml/creview/review/productreview/teasers';
  reviewProductByOrder = '/yml/creview/review/productreview/check';
  reviewsByOrder = '/yml/creview/review/productreview/exist';
  submitReviewFormProduct = '/yml/creview/review/productreview/submit';
  updateReviewFormProduct = '/yml/creview/review/productreview/update';
  uploadReviewImage = '/yml/creview/review/image/upload';
  reviewImageUpload = '/yml/creview/review/image/upload-multiple';
  deleteReview = '/yml/creview/review/productreview/delete/:reviewId';

  /**
   * merchant-info
   */

  getMerchantDetails = '/yml/merchant-info/p/:merchantId/details';
  getMerchantSupport = '/yml/merchant-info/p/:merchantId/support';

  /**
   * KL
   */
  getCityPolygon = '/yml/gis/api/city/polygon';
  getPostomatPoints = '/yml/postomat/api/external/v1/postomats/delivery/points';
  getPostomatPointsDates =
    '/yml/postomat/api/external/v1/postomats/delivery/dates';
  getPostomatNeighborOptions =
    '/yml/postomat/api/external/v1/postomats/delivery/dates/options';
  getNearbyPostomat =
    '/yml/postomat/api/external/v1/postomats/delivery/points/nearest';

  /**
   * Merchant delivery
   */
  getAvailableSlots = '/yml/merchant-delivery/slots/available';
  getSpecificCategoriesSlots =
    '/yml/merchant-delivery/slots/specific-categories';

  /**
   * main-navigation
   */
  mainNavigationGateway = '/yml/main-navigation/n/n/';

  /**
   * Promotions
   */

  activePromotions = '/yml/promotions/list/active';

  /**
   * PCM endpoints (host name is set in BACKEND.config.pcmBaseHost)
   */

  pcmMain = '/main-with-ads';
  pcmCrossSell = '/cart';
  pcmAdsTYP = '/shop-thank-you-page/withAdsContent';
  pcmRelatesCrossSell = '/shop-backdrop';
  pcmItemPageTeasers = '/item-card';
  pcmMagnumLanding = '/grocery-main';
  pcmMagnumCategoryLanding = '/supermarket/category';
  pcmMerchantRecommendations = '/free-delivery';
  pcmDiscountSearch = '/shop/discount/search';
  pcmMagnumOnboardingStories = '/stories/personalization';

  /**
   * translates
   */

  getTranslationsByPrefix = '/yml/translations';

  /**
   * prize draw
   */
  getPrizeDrawData = '/shop/ab/tests/script?code=config-json';

  /**
   * ab-tests script json
   */
  abTestsScript = '/shop/ab/tests/script';
}
